import './about.css'
import i18next from "i18next";
import {getLanguage} from "../../helper/persistance-storege";

const About = () => {
    const lang = getLanguage();
    const videoPath = "video/itm.mp4";

    return (
        <div className="container">
            <div className="about_container">
                <div className="content__top">
                    <div className="content__title">
                        <h2>{i18next.t('about_company')}</h2>
                    </div>
                </div>
                <div className={'video_content'}>
                    <video
                        controls
                        style={{
                            width: '100%',
                            height: '90%',
                        }}
                        poster={'image/about.png'}
                        loop
                        muted
                    >
                        <source src={videoPath} type={'video/mp4'}/>
                    </video>
                </div>
                <div className="content__text">
                    <div className={"work"}> {lang === 'uz' ? (
                            <p>
                                <strong>
                                    "{i18next.t('company_name')}"
                                </strong>
                                &nbsp;mas'uliyati cheklangan jamiyati 2018 yildan o‘z faoliyatini olib bormoqda. O‘zbekiston
                                Respublikasi Vazirlar Mahkamasining qaroriga binoan Mudofaa sanoati davlat qo‘mitasi
                                qoshida "Innovatsion texnologiyalar markazi" MChJ tashkil etildi. Bugungi kunda yuqori
                                tajribaga ega mutaxassislarga ega va zamonaviy dastgohlar bilan jihozlangan. Bizda ishlab
                                chiqarilayotgan mahsulotlarni sifat darajasi
                                bo‘yicha xorijiy yetakchi davlatlar standartlariga to‘liq javob beradi. Bizni korxona doimiy
                                ravishda mahsulotlarini qulaylik darajasini va sifat darajasini oshirish hamda dizayni
                                ustida ish olib boradi.</p>)
                        : (lang === 'ru') ? (
                            <p>Общество с ограниченной ответственностью&nbsp;
                                <strong>
                                    "{i18next.t('company_name')}"
                                </strong>
                                &nbsp;осуществляет свою деятельность с 2018 года. «Центр инновационных технологий»
                                создан при Госкомоборонпроме согласно
                                постановления Кабинета Министров Республики Узбекистан. На сегодняшний день
                                организация располагает ресурсом высококвалифицированных специалистов и современного
                                оборудования.Качество нашей продукции полностью соответствует стандартам ведущих
                                зарубежных стран.Наша компания постоянно работает над повышением уровня комфорта и
                                качества своей продукции, а также над дизайном изделий.
                            </p>) : (
                            <p>The limited liability company
                                &nbsp;<strong>
                                    "{i18next.t('company_name')}"
                                </strong>
                                &nbsp;has been operating since 2018. The “Center for Innovative Technologies” was
                                created under the State Defense Industry Committee in accordance with the resolution of
                                the Cabinet of Ministers of the Republic of Uzbekistan. Today, the organization has a
                                resource of highly qualified specialists and modern equipment. The quality of our
                                products fully complies with the standards of leading foreign countries. Our company is
                                constantly working to improve the level of comfort and quality of its products, as well
                                as on product design.
                            </p>)
                    }
                    </div>
                    <div className={"work"}>
                        <strong>
                            {i18next.t('our_task')}:
                        </strong>
                        <ul style={{listStyle: 'initial', marginTop: '10px'}}>
                            <li>{i18next.t('a_li_1')}</li>
                            <li>{i18next.t('a_li_2')}</li>
                            <li>{i18next.t('a_li_3')}</li>
                            <li>{i18next.t('a_li_4')}</li>
                            <li>{i18next.t('a_li_5')}</li>
                            <li>{i18next.t('a_li_6')}</li>
                            <li>{i18next.t('a_li_7')}</li>
                            <li>{i18next.t('a_li_8')}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;