import './app.css';
import {Route, Routes} from 'react-router-dom';
import {
    About,
    AdminPanel,
    Products,
    CategoryCreate,
    CategoryList,
    Contact,
    FavouriteProduct,
    Footer,
    Login,
    Main,
    Navbar,
    News,
    NewsList,
    Product,
    ProductCreate, QuestionList,
    Register,
    ScrollToTop, UserList
} from "../index";
import {useEffect} from "react";
import {getFavouriteIds, getItem} from "../../helper/persistance-storege";
import {signWithToken} from "../../slice/authSlice";
import AuthService from "../../service/authService";
import {useDispatch, useSelector} from "react-redux";
import {clearError, setNavbarHeight} from "../../slice/generalVariable";
import NewsCreate from "../news/newsCreate";
import useWindowDimensions from "../useWindowDimensions";
import ProductWithLanguage from "../product/productWithLanguage";
import CleanState from "../cleanState";
import {setFavouriteIds} from "../../slice/categorySlice";

const App = () => {
    const dispatch = useDispatch()
    const {error, navbarHeight} = useSelector(state => state.generalVariable)
    const {height, width} = useWindowDimensions();

    const getUser = async () => {
        try {
            const response = await AuthService.getUser();
            if (response.success) {
                dispatch(signWithToken(response.data))
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const accessToken = getItem("accessToken")
        const refreshToken = getItem("refreshToken")

        if (accessToken && refreshToken) {
            getUser().catch(error => console.log(error));
        }
    }, []);

    useEffect(() => {
        dispatch(setFavouriteIds(getFavouriteIds()))
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(clearError())
        }, 10000);
        return () => clearTimeout(timer);
    }, [error]);

    useEffect(() => {
        if (width <= 420) {
            dispatch(setNavbarHeight(76))
        }
        if (width >= 420) {
            dispatch(setNavbarHeight(60))
        }
    }, [height, width]);

    return (
        <>
            <ScrollToTop/>
            <Navbar/>
            <div className={"main-container"} style={{
                minHeight: (height - navbarHeight) + 'px',
                marginTop: navbarHeight + 'px'
            }}>
                <CleanState>
                    <Routes>
                        <Route index element={<Main/>}/>
                        <Route path={"login"} element={<Login/>}/>
                        <Route path={"about"} element={<About/>}/>
                        <Route path={"contact"} element={<Contact/>}/>
                        <Route path={"category"} element={<Products/>}/>
                        <Route path={"product-favourite"} element={<FavouriteProduct/>}/>
                        <Route path={"product/:productId"} element={<Product/>}/>
                        <Route path={"product/:productId/:lang"} element={<ProductWithLanguage/>}/>
                        <Route path={"news-list"} element={<NewsList/>}/>
                        <Route path={"news/:id"} element={<News/>}/>
                        <Route path={"admin-page"} element={<AdminPanel/>}>
                            <Route path={"user-list"} element={<UserList/>}/>
                            <Route path={"register"} element={<Register/>}/>
                            <Route path={"news-create"} element={<NewsCreate/>}/>
                            <Route path={"news-create/:id"} element={<NewsCreate/>}/>
                            <Route path={"category-create"} element={<CategoryCreate/>}/>
                            <Route path={"category-create/:id"} element={<CategoryCreate/>}/>
                            <Route path={"category-list"} element={<CategoryList/>}/>
                            <Route path={"product-create"} element={<ProductCreate/>}/>
                            <Route path={"product-update/:id"} element={<ProductCreate/>}/>
                            <Route path={"question-list"} element={<QuestionList/>}/>
                        </Route>
                    </Routes>
                </CleanState>
            </div>
            <Footer/>
        </>
    );
};

export default App;

