import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {signUserSuccess} from "../../slice/authSlice";
import AuthService from "../../service/authService";
import ValidationError from "../../ui/validationError";
import {Input} from "../../ui";
import {useNavigate} from "react-router-dom";
import {failure} from "../../slice/generalVariable";
import i18next from "i18next";
import {logo} from "../../assets";

const Login = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const {loggedIn} = useSelector(state => state.authSlice)
    const navigate = useNavigate()

    useEffect(() => {
        if (loggedIn) navigate("/")
    }, [loggedIn]);

    const registerHandler = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            const user = {username: username, password: password}
            console.log(user);
            const response = await AuthService.authenticate(user);
            if (response.success) {
                dispatch(signUserSuccess(response.data))
            } else {
                dispatch(failure(response.appErrorDto))
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={"text-center"} style={{paddingTop: '15vh'}}>
            <main className={"form-signin m-auto"}>
                <form>
                    <img className="mb-2" src={logo} alt="" width="57" height="57"/>
                    <h1 className="h3 mb-3 fw-normal">{i18next.t("please_login")}</h1>
                    <ValidationError/>
                    <Input label={i18next.t("username")} state={username} setState={setUsername}/>
                    <Input label={i18next.t("password")} type={'password'} state={password} setState={setPassword}/>
                    <button
                        className="mt-2 w-100 btn btn-lg btn-primary"
                        type="submit"
                        onClick={registerHandler}
                        disabled={isLoading || username.length === 0 || password.length === 0}
                    >
                        {isLoading ? `${i18next.t("loading")}...` : `${i18next.t("login")}`}
                    </button>
                </form>
            </main>
        </div>
    );
};

export default Login;