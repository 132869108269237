import axios from "./api";

const QuestionService = {

    async writeQuestion(question) {
        return (await axios.post("question/create", question)).data;
    },

    async getAllQuestions() {
        return (await axios.get("question/getAll")).data;
    },

    async delete(id) {
        await axios.delete(`question/delete/${id}`)
    },

}

export default QuestionService;