import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    news: null,
    isLoading: false,
    newsList: [],
    totalCount: -1
}

export const newsSlice = createSlice({
    name: "news",
    initialState: initialState,
    reducers: {
        newsStart: (state) => {
            state.isLoading = true
            state.news = null
            state.newsList = null
            state.totalCount = -1
        },
        newsSuccess: (state) => {
            state.isLoading = false;
        },
        newsFailure: (state) => {
            state.isLoading = false
        },
        newsGetSuccess: (state, action) => {
            state.isLoading = false
            state.news = action.payload
        },
        getAllNewsSuccess: (state, action) => {
            state.isLoading = false
            state.newsList = action.payload.data
            state.totalCount = action.payload.totalCount
        }
    }
})

export const {newsStart, newsSuccess, newsGetSuccess, newsFailure, getAllNewsSuccess} = newsSlice.actions
export default newsSlice.reducer