import axios from "./api";

const CategoryService = {

    async add(category) {
        return (await axios.post("category/add", category)).data;
    },

    async getAllForAdmin() {
        return (await axios.get("category/getAllForAdmin")).data;
    },

    async get(id) {
        return (await axios.get(`category/get/${id}`)).data;
    },

    async delete(id) {
        await axios.delete(`category/delete/${id}`);
    },

    async getAll() {
        return (await axios.get(`category/getAll`)).data;
    },

    async getAllProduct(filter) {
        return (await axios.get('product/getAll', {params: filter})).data;
    },

    async update(category) {
        return (await axios.put("category/update", category)).data;
    },

}

export default CategoryService;