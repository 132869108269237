export const phoneNumber = '+998 71 230 04 02'
export const email = 'itm.mchj@itm-llc.uz'
export const baseUrl = "https://backend.itm-llc.uz:8080/"
export const fileDownload = baseUrl + 'file/download/'
export const baseUrlForFrontend = "https://itm-llc.uz/"

export const enMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
export const ruMonths = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
export const uzMonths = ['Yanvar', 'Fevral', 'Mart', 'Aprel', 'May', 'Iyun', 'Iyul', 'Avgust', 'Sentyabr', 'Oktyabr', 'Noyabr', 'Dekabr']
export const sortListUz = [ 'Oxirgi','Reyting']
export const sortListRu = [ 'Последние','Рейтинг']
export const sortListEn = [ 'Latest','Rating']