import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {closeSidebar, failure} from "../../slice/generalVariable";
import '../../index.css'
import CategoryService from "../../service/categoryService";
import {failureCategory, getCategoriesSuccess, setCategoryId, startCategory} from "../../slice/categorySlice";
import {Img} from "../../ui";
import i18next from "i18next";
import {fileDownload} from "../../constant";

const Sidebar = ({setCategoryImagesLoad}) => {
    const dispatch = useDispatch();
    const {isOpenSideBar} = useSelector(state => state.generalVariable);
    const {categories} = useSelector(state => state.categorySlice);

    useEffect(() => {
        const fetchData = async () => {
            dispatch(startCategory());
            try {
                const response = await CategoryService.getAll();
                if (response.success) {
                    dispatch(getCategoriesSuccess(response.data));
                } else {
                    dispatch(failure(response.appErrorDto));
                    dispatch(failureCategory());
                }
            } catch (error) {
                dispatch(failureCategory());
                console.log(error);
            }
        }
        fetchData().catch(error => console.log(error))
    }, [])

    useEffect(() => {
        if (categories && categories.length !== 0) {
            setCategoryImagesLoad(false)
            const loadImage = src =>
                new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = () => resolve(img)
                    img.onerror = reject;
                    img.src = src;
                })

            Promise
                .all(categories.map(category => loadImage(`${fileDownload}${category.pictureUrl}`)))
                .then((images) => {
                    setCategoryImagesLoad(true)
                })
                .catch(err => console.log("Failed to load images", err))
        }
    }, [categories])

    const handleCategoryClick = (categoryId) => {
        dispatch(setCategoryId(categoryId));
    };

    const renderCategories = () => {
        return categories.map((category) => (
            <div className="widget" key={category.id} onClick={() => handleCategoryClick(category.id)}>
                <div className="category-container">
                    <Img
                        pictureUrl={category.pictureUrl}
                        borderRadius={5}
                        classes={"category-image"}
                    />
                    <div className="text-container">
                        <p className="picture-name">{category.name}</p>
                    </div>
                </div>
            </div>
        ));
    };


    return (
        <div className={`sidebar ${isOpenSideBar ? 'active' : ''}`} style={{marginTop: "2vh"}}>
            <div className="wrap">
                <a
                    href="#0"
                    className="close-trigger"
                    onClick={() => dispatch(closeSidebar())}
                    style={{top: "49%"}}
                >
                    <i className="ri-close-line"></i>
                </a>
                <div className="sidebar-content">
                    <div className="widget" key={0} onClick={() => dispatch(setCategoryId(""))}>
                        <div className="category-container">
                            <div className="text-container">
                                <p className="picture-name text-center">{i18next.t("all")}</p>
                            </div>
                        </div>
                    </div>
                    {renderCategories()}
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
