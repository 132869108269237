import React, {useEffect, useState} from 'react';
import '../../index.css'
import {useDispatch, useSelector} from "react-redux";
import {failure} from "../../slice/generalVariable";
import {CategoryProduct} from "../index";
import {failureCategory, getAllProductSuccess, startCategory} from "../../slice/categorySlice";
import CategoryService from "../../service/categoryService";
import {Loading} from "../../ui";
import {fileDownload} from "../../constant";
import i18next from "i18next";

const FavouriteProduct = () => {
    const dispatch = useDispatch()
    const {
        products,
        isLoading,
        favouriteIds,
        productTotalCount,
    } = useSelector(state => state.categorySlice)
    const [productImagesLoad, setProductImagesLoad] = useState(false)

    useEffect(() => {
        getAllProduct()
            .catch(error => console.log(error))
    }, [])

    useEffect(() => {
        if (!isLoading && productTotalCount === 0) {
            setProductImagesLoad(true);
        }
        if (products && products.length !== 0) {
            setProductImagesLoad(false);
            const loadImage = (src) =>
                new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = () => resolve(img);
                    img.onerror = reject;
                    img.src = src;
                });

            Promise.all(products.map((product) => loadImage(`${fileDownload}${product.pictureUrl}`)))
                .then((images) => {
                    setProductImagesLoad(true);
                })
                .catch((err) => console.log("Failed to load images", err));
        }
    }, [isLoading, productTotalCount, products, setProductImagesLoad, fileDownload]);


    const getAllProduct = async () => {
        setProductImagesLoad(false)
        dispatch(startCategory())
        try {
            const filter = {
                "searchValue": "",
                "sorting": "",
                "categoryId": ""
            }
            const response = await CategoryService.getAllProduct(filter)

            if (response.success) {
                dispatch(getAllProductSuccess(response.data))
            } else {
                dispatch(failure(response.appErrorDto))
                dispatch(failureCategory())
            }
        } catch (error) {
            console.log(error)
            dispatch(failureCategory())
        }
    }

    return (
        <>
            {(isLoading || !productImagesLoad) && <Loading/>}
            {products &&
                <div className={"container"}
                     style={{display: isLoading || !productImagesLoad ? "none" : "block"}}
                >
                    <div className={'page-category'}>
                        <main>
                            <div className={'section'}>
                                <div className="container wide">
                                    <div className="wrap">
                                        <div className="content">
                                            <h2 style={{marginBottom:"5%",textAlign:"center"}}>{i18next.t("desires")}</h2>
                                            <div className="category-content">
                                                <div className="dotgrid">
                                                    <div className="wrapper">
                                                        {products.map(product => (
                                                            favouriteIds.includes(product.id) &&
                                                            <CategoryProduct key={product.id}
                                                                             pictureUrl={product.pictureUrl}
                                                                             name={product.name}
                                                                             price={product.price}
                                                                             id={product.id}
                                                                             isFavourite={favouriteIds && favouriteIds.includes(product.id)}
                                                            />))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>}
        </>
    );
};

export default FavouriteProduct;