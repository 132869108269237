import React, {useEffect, useState} from 'react';
import {getItem} from "../helper/persistance-storege";
import {loaderEn, loaderRu, loaderUz} from "../assets";

const Loader = () => {
    const [loader, setLoader] = useState()

    useEffect(() => {
        const lang = getItem("lang")

        if (lang === 'uz') {
            setLoader(loaderUz)
        } else if (lang === 'ru') {
            setLoader(loaderRu)
        } else {
            setLoader(loaderEn)
        }
    }, []);

    return (
        <>
            {loader && <div className={'video_content'}>
                <video
                    style={{
                        width: '100%',
                        height: '95vh',
                    }}
                    loop
                    muted
                    autoPlay
                >
                    <source src={loader} type={'video/mp4'}/>
                </video>
            </div>}
        </>
    );
};

export default Loader;

// <div className={'container'} style={{
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     height: '60vh'
// }}>
//     <PacmanLoader color={'#228B22'} size={75}/>
// </div>