import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import CategoryService from "../../service/categoryService";
import {useDispatch, useSelector} from "react-redux";
import {failureCategory, getCategoriesSuccess, startCategory,} from "../../slice/categorySlice";
import {failure} from "../../slice/generalVariable";
import {fileDownload} from "../../constant";
import {DisplayList} from "../index";

const CategoryList = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {isLoading, categories} = useSelector(state => state.categorySlice)

    const getCategories = async () => {
        dispatch(startCategory())
        try {
            const response = await CategoryService.getAllForAdmin();
            if (response.success) {
                dispatch(getCategoriesSuccess(response.data))
            } else {
                dispatch(failure(response.appErrorDto))
                dispatch(failureCategory())
            }
        } catch (error) {
            console.log(error)
            dispatch(failureCategory())
        }
    }


    useEffect(() => {
        getCategories().catch(error => console.log(error))
    }, []);


    const editHandler = (id) => {
        navigate(`/admin-page/category-create/${id}`)
    };

    const deleteHandler = async (id) => {
        await CategoryService.delete(id);
        window.location.reload();
    };

    const headers = [
        {
            Header: "Uz",
            accessor: "uz",
        },
        {
            Header: "Ru",
            accessor: "ru",
        },
        {
            Header: "En",
            accessor: "en",
        },
        {
            Header: "Ko'rinadimi",
            accessor: d => {
                return d.hidden ? "Yo'q" : "Ha";
            }
        },
        {
            Header: "Picture",
            accessor: "generated_name_of_image",
            Cell: props => (
                <div className="text-center">
                    <img
                        src={`${fileDownload}${props.row.original.pictureUrl}`}
                        alt={"category image"}
                        width={'58px'}
                        height={'58px'}
                        style={{borderRadius: "5px"}}
                    />
                </div>
            )
        },]

    return (
        <DisplayList
            data={categories}
            headers={headers}
            editHandler={editHandler}
            deleteHandler={deleteHandler}
            isLoading={isLoading}
            createUrl={"/admin-page/category-create"}
        />
    );
};

export default CategoryList;