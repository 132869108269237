import {useEffect} from "react";
import {setAlertMessage} from "../slice/generalVariable";
import {useDispatch, useSelector} from "react-redux";

const AlertSuccess = () => {
    const {alertMessage} = useSelector(state => state.generalVariable)
    const dispatch = useDispatch()

    useEffect(() => {
        if (alertMessage) {
            const timer = setTimeout(() => {
                dispatch(setAlertMessage(""))
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [alertMessage]);

    return (
        alertMessage && <div className="alert alert-success" role="alert">
            {alertMessage}
        </div>
    );
};

export default AlertSuccess;