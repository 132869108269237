import axios from "./api";


const AuthService = {

    async userRegister(user) {
        return (await axios.post("user/register", user));
    },

    async authenticate(user) {
        return (await axios.post("auth/authenticate", user)).data;
    },

    logout() {
        axios.post("auth/logout");
    },

    async getUser() {
        return (await axios.get("user/get")).data;
    },

    async getAll() {
        return (await axios.get("user/getAll")).data;
    },

    async delete(username) {
        return (await axios.delete(`user/delete/${username}`))
    },

}

export default AuthService;