import React from 'react';

const  Select = ({setState, options, selected,classes}) => {
    return (
        <div style={{marginBottom: "10px"}}>
            <select
                className={`form-select ${classes}`}
                value={selected}
                onChange={(e) => setState(e.target.value)}
            >
                {options.map((option,index) => (<option value={option.id} key={index}>{option.name}</option>))}
            </select>
        </div>
    );
};

export default Select;