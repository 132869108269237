import React, {useMemo} from 'react';
import {useTable} from "react-table";
import {useNavigate} from "react-router-dom";
import {Loading} from "./index";

const DisplayList = ({data, headers, editHandler, deleteHandler, isLoading, createUrl, addButtonHidden = false}) => {
    const navigate = useNavigate()

    const actions = {
        Header: "Actions",
        accessor: "actions",
        Cell: (props) => {
            const rowIdx = props.row.original.id;
            return (
                <div>
                    {editHandler && (<span onClick={() => editHandler(rowIdx)}>
                            <button type="button" className="btn btn-warning btn-sm mx-2">Edit</button>
                        </span>
                    )}
                    <span onClick={() => deleteHandler(rowIdx)}>
                            <button type="button" className="btn btn-danger btn-sm">Delete</button>
                        </span>
                </div>
            );
        }
    };

    const columns = useMemo(() => createUrl ? [...headers, actions] : [...headers], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data: data,
    });

    return (
        <>
            {(isLoading || data === null) && <Loading/>}
            {(!isLoading && data !== null) &&
                <div className={"container"}>
                    <div className="list row" style={{marginTop: "5vh"}}>
                        <div className="col-md-12 list">
                            <table
                                className="table table-striped table-bordered"
                                {...getTableProps()}
                            >
                                <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th {...column.getHeaderProps()}>
                                                {column.render("Header")}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                {rows.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (
                                                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {!addButtonHidden &&
                        <div className="d-flex justify-content-center">
                            <button onClick={() => navigate(createUrl)} style={{height: '58px', width: "58px"}}>
                                +
                            </button>
                        </div>}
                </div>}
        </>
    );
};

export default DisplayList;