import React from 'react';
import {fileDownload} from "../constant";

const Img = ({pictureUrl, borderRadius = 10, classes, onLoad}) => {
    const loadHandler = () => {
        onLoad && onLoad()
    }

    return (
        <img
            src={`${fileDownload}${pictureUrl}`}
            alt={"image has"}
            style={{borderRadius: `${borderRadius}px`}}
            className={`${classes}`}
            onLoad={() => loadHandler()}
        />
    );
};

export default Img;