import React from 'react';
import i18next from "i18next";

const Search = ({label, setState, state, getAllProduct}) => {
    return (
        <div className="row justify-content-md-center">
            <div className="col-md-8">
                <div className="input-group mb-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={label}
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                    />
                    &nbsp; &nbsp;
                    <div className="input-group-append">
                        <button
                            className="btn btn-outline-success"
                            type="button"
                            onClick={getAllProduct}
                        >
                            {i18next.t("search")}
                        </button>
                    </div>
                </div>
            </div>
        </div>);
};

export default Search;