import React from 'react';
import {Link, Outlet, useNavigate} from "react-router-dom";
import i18next from "i18next";
import {useDispatch, useSelector} from "react-redux";
import AuthService from "../../service/authService";
import {logout} from "../../slice/authSlice";

function AdminPanel() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {user} = useSelector(state => state.authSlice)

    const logoutHandler = () => {
        dispatch(logout());
        navigate('/');
        AuthService.logout();
    };

    return (
        <div style={{display: user?.role === 'SUPER_ADMIN' || user?.role === 'ADMIN' ? 'block' : "none"}}>
            <div className="d-flex">
                <div className="w-auto">
                    <div
                        className={"sidebar d-flex justify-content-between flex-column bg-dark text-white p-3 ps-3"}
                        style={{height: "93vh"}}>
                        <div>
                            <a href="" className={"p-3"}>
                                <i className="bi bi-code-slash fs-4 me-4"></i>
                                <span className={"fs-4"}>Admin Panel</span>
                            </a>
                            <hr className={"text-secondary mt-2"}/>
                            <ul className={"nav nav-pills flex-column mt-2 gap-2"} id={"parentM"}>
                                <li className="dropdown">
                                    <button className="btn btn-secondary dropdown-toggle w-100" type="button"
                                            id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        News
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><Link className="dropdown-item" to={'news-create'}>Create</Link></li>
                                    </ul>
                                </li>
                                {user?.role === 'SUPER_ADMIN' && <li className="dropdown">
                                    <button className="btn btn-secondary dropdown-toggle w-100" type="button"
                                            id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                        User
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <li><Link className="dropdown-item" to={'register'}>Create</Link></li>
                                        <li><Link className="dropdown-item" to={'user-list'}>User List</Link></li>
                                    </ul>
                                </li>}
                                <li className="dropdown">
                                    <button className="btn btn-secondary dropdown-toggle w-100" type="button"
                                            id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-expanded="false">
                                        Category
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <li><Link className="dropdown-item" to={'category-create'}>Create</Link></li>
                                        <li><Link className="dropdown-item" to={'category-list'}>List</Link></li>
                                    </ul>
                                </li>
                                <li className="dropdown">
                                    <button className="btn btn-secondary dropdown-toggle w-100" type="button"
                                            id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-expanded="false">
                                        Product
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <li><Link className="dropdown-item" to={'product-create'}>Create</Link></li>
                                    </ul>
                                </li>
                                <li className="dropdown">
                                    <button className="btn btn-secondary dropdown-toggle w-100" type="button"
                                            id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-expanded="false">
                                        Question
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <li><Link className="dropdown-item" to={'question-list'}>List</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="btn-group dropup">
                            <button
                                className={'btn btn-outline-danger'}
                                onClick={logoutHandler}
                            >
                                {i18next.t('logout')}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <Outlet/>
                </div>
            </div>
        </div>
    );
}

export default AdminPanel;