import React from 'react';
import '../index.css'
import {Link} from "react-router-dom";

const Actions = ({updateHandler, deleteHandler}) => {
    return (
        <>
            <div className="actions">
                <ul>
                    <li>
                        <div onClick={updateHandler}>
                            <i className="ri-edit-box-line"></i>
                        </div>
                    </li>
                    <li>
                        <div onClick={deleteHandler} className={"delete-icon"}>
                            <i className="ri-delete-bin-7-line"></i>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default Actions;