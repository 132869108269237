import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import AuthService from "../../service/authService";
import {AlertSuccess, Input} from "../../ui";
import ValidationError from "../../ui/validationError";
import {failure, setAlertMessage} from "../../slice/generalVariable";
import i18next from "i18next";
import {logo} from "../../assets";

const Register = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const clearState = () => {
        setUsername("")
        setPassword("")
    }

    const registerHandler = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const user = {username: username, password: password}
        try {
            const response = await AuthService.userRegister(user);

            if (!response.data.success) {
                dispatch(failure(response.data.appErrorDto))
            } else {
                dispatch(setAlertMessage(i18next.t("register_success")))
            }
            clearState()
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={"text-center"} style={{paddingTop: '15vh'}}>
            <main className={"form-signin m-auto"}>
                <form>
                    <img className="mb-2" src={logo} alt="" width="57" height="57"/>
                    <h1 className="h3 mb-3 fw-normal">{i18next.t("please_register")}</h1>
                    <ValidationError/>
                    <AlertSuccess/>
                    <Input label={i18next.t("username")} setState={setUsername} state={username}/>
                    <Input label={i18next.t("password")} type={'password'} setState={setPassword} state={password}/>
                    <button
                        className="mt-2 w-100 btn btn-lg btn-primary"
                        type="submit"
                        onClick={registerHandler}
                        disabled={isLoading || username.length === 0 || password.length === 0}
                    >
                        {isLoading ? `${i18next.t("loading")}...` : `${i18next.t("register")}`}
                    </button>
                </form>
            </main>
        </div>
    );
};

export default Register;