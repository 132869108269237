export const setItem = (key, data) => {
    try {
        localStorage.setItem(key, data)
    } catch (error) {
        console.log(error)
    }
}

export const getItem = key => {
    try {
        return localStorage.getItem(key)
    } catch (error) {
        console.log(error)
        console.log("Error saving")
        setItem("lang", "uz")
        return 'uz'
    }
}

export const deleteItem = key => {
    try {
        localStorage.removeItem(key)
    } catch (error) {
        console.log(error)
    }
}

export const getLanguage = () => {
    try {
        const lang = localStorage.getItem("lang")
        if (lang && lang.length > 0) {
            return lang;
        }
        setItem("lang", "uz")
        return "uz"
    } catch (error) {
        console.log(error)
        setItem("lang", "uz")
        return 'uz';
    }
}

export const getFavouriteIds = function () {
    try {
        return JSON.parse(localStorage.getItem("favouriteIds"))
    } catch (error) {
        console.log(error)
        return []
    }
}

export const setFavouriteIdsToLocalStorage = function (obj) {
    return localStorage.setItem("favouriteIds", JSON.stringify(obj))
}