import {createSlice} from "@reduxjs/toolkit";
import {deleteItem, setItem} from "../helper/persistance-storege";

const initialState = {
    isLoading: false,
    loggedIn: false,
    user: null,
    users: [],
}

export const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        signUserSuccess: (state, action) => {
            state.loggedIn = true
            state.error = null
            state.user = action.payload.user
            setItem('accessToken', action.payload.accessToken)
            setItem('refreshToken', action.payload.refreshToken)
        },
        signWithToken: (state, action) => {
            state.loggedIn = true
            state.user = action.payload
        },
        logout: (state) => {
            state.loggedIn = false
            state.user = null
            deleteItem('accessToken')
            deleteItem('refreshToken')
        },
        getUsersStart: (state) => {
            state.isLoading = true;
        },
        getUsersSuccess: (state, action) => {
            state.isLoading = false;
            state.users = action.payload;
        },
        getUserFailure: (state) => {
            state.isLoading = false;
        },
    }
})

export const {
    signUserSuccess,
    signWithToken,
    logout,
    getUsersStart,
    getUsersSuccess,
    getUserFailure,
} = authSlice.actions

export default authSlice.reducer