import './contact.css'
import React from "react";
import {Socials} from "../../ui";
import i18next from "i18next";
import {email, phoneNumber} from "../../constant";
import {Link} from "react-router-dom";

const Contact = () => {
    return (
        <div className={'my-container'}>
            <div className="content__holder">
                <div className="row">
                    <div className={'col-md-6 col-12'}>
                        <div className="my-sidebar">
                            <div>
                                <div className="sidebar__socials-item">
                                    <div className="sidebar__socials-title">{i18next.t('phone')}:</div>
                                    <Link to="#" className="sidebar__socials-bold bold-text">{phoneNumber}</Link>
                                </div>
                                <div className="sidebar__socials-item">
                                    <div className="sidebar__socials-title">{i18next.t('working_time')}:</div>
                                    <h6 className="sidebar__socials-text bold-text">9:00-18:00</h6>
                                </div>
                                <div className="sidebar__socials-item">
                                    <div className="sidebar__socials-title">{i18next.t('weekend')}:</div>
                                    <h6 className="sidebar__socials-text bold-text">{i18next.t('weekend_days')}</h6>
                                </div>
                                <div className="sidebar__socials-item">
                                    <div className="sidebar__socials-title">{i18next.t('email')}:</div>
                                    <Link to="https://mail.ru/itm.mchj@itm-llc.uz"
                                          className="sidebar__socials-bold bold-text">{email}</Link>
                                </div>
                                <div className="sidebar__socials-item">
                                    <div className="sidebar__socials-title">{i18next.t('address')}:</div>
                                    <h6 className="sidebar__socials-text">{i18next.t('address_name')}</h6>
                                </div>
                                <div className="sidebar__socials-item">
                                    <div className="sidebar__socials-title">{i18next.t('address_text')}:</div>
                                    <div className="sidebar__socials-wrap">
                                        <Socials/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'col-md-6  col-12'}>
                        <div className="content__main">
                            <div className="content__top-secondary content__top">
                                <h2 className="content__title">{i18next.t("our_address")}</h2>
                            </div>
                            <div className="map__block" style={{height: '50vh', marginTop: '30px', width: '100%'}}>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2999.444837353698!2d69.19410711173425!3d41.255649404036696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8bf1198ed539%3A0x1d244ab4e8ac65cc!2s%22Innovatsiya%20texnologiyalari%20markazi%22%20MChJ!5e0!3m2!1sen!2s!4v1707372261148!5m2!1sen!2s"
                                    allowFullScreen=""
                                    loading="lazy"
                                    width={'100%'}
                                    height={'100%'}
                                    referrerPolicy="no-referrer-when-downgrade">
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
