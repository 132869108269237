import {configureStore} from "@reduxjs/toolkit";
import GeneralVariableReducer from '../slice/generalVariable'
import AuthReducer from "../slice/authSlice"
import ProductReducer from "../slice/productSlice"
import CategoryReducer from "../slice/categorySlice"
import NewsReducer from "../slice/newsSlice"

export const store = configureStore({
    reducer: {
        generalVariable: GeneralVariableReducer,
        authSlice: AuthReducer,
        productSlice: ProductReducer,
        categorySlice: CategoryReducer,
        newsSlice: NewsReducer
    },
    devTools: process.env.NODE_ENV !== "production"
})