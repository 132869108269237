import '../../index.css'
import {Img} from "../../ui";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import i18next from "i18next";
import {useDispatch} from "react-redux";
import {toggleFavourite} from "../../slice/categorySlice";

const CategoryProduct = ({id, pictureUrl, name, price, isFavourite}) => {
    const [favourite, setFavourite] = useState(isFavourite)
    const dispatch = useDispatch()

    const toggle = () => {
        console.log(id)
        dispatch(toggleFavourite(id))
        setFavourite(prevState => {
            return !prevState;
        })
    }

    return (
        <div className="item swiper-slide">
            <Link to={`/product/${id}`}>
                <div className="dot-image">
                    <a href="#0" className="product-permalink"></a>
                    <div className="thumbnail">
                        <Img
                            pictureUrl={pictureUrl}
                            classes={"category-product-image"}
                        />
                    </div>
                </div>
            </Link>
            <div className={'favourite'} onClick={toggle}>
                {favourite ?
                    <i className="ri-heart-fill"></i> :
                    <i className="ri-heart-line"></i>
                }
            </div>
            <div className="dot-info">
                <h2 className="dot-title">
                    <a href="">{name}</a>
                </h2>
                <div className="product-price">
                    <span
                        className="current">{price !== 0 ? `${price} ${i18next.t("som")}` : `${i18next.t("agreed")}`}</span>
                </div>
            </div>
        </div>
    );
};

export default CategoryProduct;