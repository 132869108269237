import axios from "axios";
import {deleteItem, getItem, getLanguage, setItem} from "../helper/persistance-storege";
import {baseUrl} from "../constant";


axios.defaults.baseURL = baseUrl
axios.defaults.headers.common['Accept-Language'] = getLanguage();
axios.defaults.headers.common['Cache-Control'] = 'no-cache';
axios.defaults.headers.common['Pragma'] = "no-cache";
axios.defaults.headers.common['Expires'] = '0';

const refreshTokenAxios = axios.create();

axios.interceptors.request.use(
    config => {
        const token = getItem("accessToken")
        config.headers.Authorization = token ? `Bearer ${token}` : "";

        return config;
    },
    error => {
        Promise.reject(error)
    })
refreshTokenAxios.interceptors.request.use(
    config => {
        const token = getItem("refreshToken")
        config.headers.Authorization = token ? `Bearer ${token}` : "";

        return config;
    }
)
axios.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
            const response = await refreshTokenAxios.post('/auth/refreshToken');
            const tokens = response.data.data;
            setItem('accessToken', tokens.accessToken)
            setItem('refreshToken', tokens.refreshToken)

            originalRequest.headers.Authorization = `Bearer ${tokens.accessToken}`;
            return axios(originalRequest);
        } catch (error) {
            deleteItem('accessToken')
            deleteItem('refreshToken')
            console.log(error)
        }
    }
    return Promise.reject(error);
});

export default axios;