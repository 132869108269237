import {createSlice} from "@reduxjs/toolkit";
import {uzMonths} from "../constant";

const initialState = {
    isOpenShareModal: false,
    isOpenAnswerModal: false,
    isOpenReviewModal: false,
    isOpenSideBar: true,
    error: null,
    alertMessage: null,
    months: uzMonths,
    navbarHeight: 60,
}

export const generalVariableSlice = createSlice({
    name: 'generalVariable',
    initialState: initialState,
    reducers: {
        openShareModal: (state) => {
            state.isOpenShareModal = true;
        },
        closeShareModal: (state) => {
            state.isOpenShareModal = false;
        },
        openAnswerModal: (state) => {
            state.isOpenAnswerModal = true;
        },
        closeAnswerModal: (state) => {
            state.isOpenAnswerModal = false
        },
        openReviewModal: (state) => {
            state.isOpenReviewModal = true;
        },
        closeReviewModal: (state) => {
            state.isOpenReviewModal = false;
        },
        openSidebar: (state) => {
            state.isOpenSideBar = true;
        },
        closeSidebar: (state) => {
            state.isOpenSideBar = false;
        },
        failure: (state, action) => {
            state.error = action.payload
        },
        clearError: (state) => {
            state.error = null;
        },
        setAlertMessage: (state, action) => {
            state.alertMessage = action.payload
        },
        setNavbarHeight: (state, action) => {
            state.navbarHeight = action.payload
        },
    }
})

export const {
    openShareModal,
    closeShareModal,
    openAnswerModal,
    closeAnswerModal,
    openReviewModal,
    closeReviewModal,
    openSidebar,
    closeSidebar,
    failure,
    clearError,
    setAlertMessage,
    setNavbarHeight,
} = generalVariableSlice.actions
export default generalVariableSlice.reducer