import {useSelector} from "react-redux";
import {useCallback} from "react";

const ValidationError = () => {
    const {error} = useSelector(state => state.generalVariable)

    // const errorMessage = useCallback(() => {
    //     return Object.keys(error).map(name => {
    //         const msg = error[name];
    //         return `${name}:  ${msg}`;
    //     })
    // }, [error])
    const errorMessage = useCallback(() => {
        return error.message;
    }, [error])

    console.log(error !== null && errorMessage())

    return (
        error !== null && (
            <div className="alert alert-danger m-1 p-1 text-start" role="alert"> {errorMessage()} </div>
        )
    );
};

export default ValidationError;