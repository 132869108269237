import React from 'react'
import {email, phoneNumber} from "../../constant";
import {Link} from "react-router-dom";
import i18next from "i18next";
import {Socials} from "../../ui";
import {logo} from "../../assets";
import {useSelector} from "react-redux";
import './app.css'

const Footer = () => {
    const {loggedIn} = useSelector(state => state.authSlice)

    return (
        <>
            <div className="Footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-5 col-12 ft-1">
                            <div className="left">
                                <Link to="/" className="home-link nuxt-link-exact-active nuxt-link-active"
                                      aria-current="page">
                                   <span style={{marginRight: '20px', minHeight: '45px', minWidth: '45px'}}>
                                          <img src={logo} alt="logo" width={45} height={45}
                                               style={{borderRadius: '50%'}}/>
                                   </span>
                                    <h2 className="logo-text fw-bold" style={{color: '#fff'}}>
                                        {i18next.t('company_name')}
                                    </h2>
                                </Link>
                            </div>
                            <div className="set d-none d-lg-flex social-links align-items-center">
                                <Socials/>
                            </div>
                            <div className={'footer-text'}>
                                <p>{i18next.t("ministry")}</p>
                                <p>{i18next.t("reserved")}</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 col-12 ft-2">
                            <h5>{i18next.t("menu")}</h5>
                            <ul>
                                <li className="nav-item">
                                    <Link to="/">{i18next.t('home')}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/about">{i18next.t('about_company')}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/category">{i18next.t("products")}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/news-list">{i18next.t('news')}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/contact">{i18next.t('contact')}</Link>
                                </li>
                                {!loggedIn && <li className="nav-item">
                                    <Link to="/login">{i18next.t('login')}</Link>
                                </li>}
                                {loggedIn && <li className="nav-item">
                                    <Link to="/admin-page">{i18next.t('admin_page')}</Link>
                                </li>}
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12 ft-3">
                            <h5>{i18next.t('contact')}</h5>
                            <p>
                                <i className="fa-solid fa-phone-volume" style={{color: '#009688'}}></i>
                                {i18next.t('phone')}: {phoneNumber}
                            </p>
                            <p>
                                <i className="fa-regular fa-clock"
                                   style={{color: '#2196F3'}}></i>{i18next.t('working_time')}: 8:00-17:00
                            </p>
                            <p>
                                <i className="fa-solid fa-calendar-days"
                                   style={{color: '#7CB342'}}></i>{i18next.t('weekend')}: {i18next.t('weekend_days')}
                            </p>
                            <p><i className="fa-solid fa-envelope" style={{color: '#2196F3'}}></i>
                                {i18next.t('email')}: {email}
                            </p>
                            <p>
                                <i className="fa-solid fa-location-dot" style={{color: '#7CB342'}}></i>
                                {i18next.t('address')}: {i18next.t('address_name')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
