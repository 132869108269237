import React, {useEffect, useState} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css'
import i18next from "i18next";
import './news.css'
import {Input} from "../../ui";
import {FileUpload} from "../index";
import {useDispatch, useSelector} from "react-redux";
import {newsFailure, newsGetSuccess, newsStart, newsSuccess} from "../../slice/newsSlice";
import NewsService from "../../service/newsService";
import {failure} from "../../slice/generalVariable";
import {useNavigate, useParams} from "react-router-dom";
import FileService from "../../service/fileService";

const NewsCreate = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const {isLoading} = useSelector(state => state.newsSlice)
    const navigate = useNavigate()
    const [uzTitle, setUzTitle] = useState('')
    const [ruTitle, setRuTitle] = useState('')
    const [enTitle, setEnTitle] = useState('')
    const [uzBody, setUzBody] = useState('')
    const [ruBody, setRuBody] = useState('')
    const [enBody, setEnBody] = useState('')
    const [files, setFiles] = useState(null)

    const getNews = async (id) => {
        dispatch(newsStart())
        try {
            const response = await NewsService.getForUpdate(id)

            if (response.success) {
                dispatch(newsGetSuccess(response.data))
                const title = response.data.title;
                const body = response.data.body;

                setUzTitle(title.uz)
                setRuTitle(title.ru)
                setEnTitle(title.en)
                setUzBody(body.uz)
                setRuBody(body.ru)
                setEnBody(body.en)
            } else {
                dispatch(failure(response.appErrorDto))
                dispatch(newsFailure())
            }
        } catch (error) {
            console.log(error)
            dispatch(newsFailure())
        }
    }

    useEffect(() => {
        getNews(id).catch(() => navigate('/news'))
    }, []);

    function getNewsEntity() {
        const news = {
            title: {uz: uzTitle, ru: ruTitle, en: enTitle},
            body: {uz: uzBody, ru: ruBody, en: enBody}
        }
        return news;
    }

    const clearState = () => {
        setUzTitle('')
        setRuTitle('')
        setEnTitle('')
        setUzBody('')
        setRuBody('')
        setEnBody('')
        setFiles(null)
    }

    const uploadImages = async (id) => {
        const formData = new FormData()
        const arrayFiles = Array.from(files)
        arrayFiles.forEach((file) => {
            formData.append(`files`, file);
        });
        const responseFile = await FileService.uploadNewsImages(formData, id)

        if (responseFile.success) {
            dispatch(newsSuccess())
            clearState()
        } else {
            dispatch(failure(responseFile.appErrorDto))
            dispatch(newsFailure())
        }
    }

    const updateHandler = async (e) => {
        dispatch(newsStart())

        try {
            const news = getNewsEntity()
            news.id = id
            const response = await NewsService.update(news)

            if (response.success) {
                if (files !== null) {
                    await uploadImages(id)
                } else {
                    dispatch(newsSuccess())
                    clearState()
                }
            } else {
                dispatch(failure(response.appErrorDto))
                dispatch(newsFailure())
            }
        } catch (error) {
            console.log(error)
            dispatch(newsFailure())
        }
    }

    const createHandler = async (e) => {
        dispatch(newsStart())

        try {
            const response = await NewsService.create(getNewsEntity())
            if (response.success) {
                await uploadImages(response.data);
            } else {
                dispatch(failure(response.appErrorDto))
                dispatch(newsFailure())
            }
        } catch (error) {
            console.log(error)
            dispatch(newsFailure())
        }
    }

    const saveHandler = async () => {
        if (id) {
            await updateHandler()
        } else {
            await createHandler()
        }
    }

    const isEmptyStates = () => {
        return uzTitle === '' || uzBody === '' || ruTitle === '' || ruBody === '' || enTitle === '' || enBody === '' || (files === null && !id);
    }

    const modules = {
        toolbar: [
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            [{font: []}],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                {list: 'ordered'},
                {list: 'bullet'},
                {indent: '-1'},
                {indent: '+1'},
            ],
            [{align: ''}, {align: 'center'}, {align: 'right'}, {align: 'justify'}],
            ['link']
        ]
    }


    return (
        <main>
            <div className='news-create container'>
                <section className='mainContent details'>
                    <div className="box">
                        <p><strong>Uz:</strong></p>
                        <Input label={"Sarlavha"} state={uzTitle} setState={setUzTitle}/>
                        <div className="editor">
                            <ReactQuill
                                theme="snow"
                                value={uzBody}
                                onChange={setUzBody}
                                modules={modules}
                            />
                        </div>
                    </div>
                    <div className="box">
                        <p><strong>Ru:</strong></p>
                        <Input label={'Заголовок'} state={ruTitle} setState={setRuTitle}/>
                        <div className="editor">
                            <ReactQuill
                                theme="snow"
                                value={ruBody}
                                onChange={setRuBody}
                                modules={modules}
                            />
                        </div>
                    </div>
                    <div className="box">
                        <p><strong>En:</strong></p>
                        <Input label={'Title'} state={enTitle} setState={setEnTitle}/>
                        <div className="editor">
                            <ReactQuill
                                theme="snow"
                                value={enBody}
                                onChange={setEnBody}
                                modules={modules}
                            />
                        </div>
                    </div>
                    <div className="box">
                        <p><strong>{i18next.t("upload")}:</strong></p>
                        <FileUpload setFiles={setFiles}/>
                    </div>
                </section>
                <button
                    className="mt-4 w-100 btn btn-lg btn-primary"
                    type="submit"
                    onClick={saveHandler}
                    disabled={isLoading || isEmptyStates()}
                >
                    {isLoading ? `${i18next.t("loading")}...` : `${i18next.t("save")}`}
                </button>
            </div>
        </main>
    );
};

export default NewsCreate