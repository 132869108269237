import React, {useEffect, useState} from 'react';
import './newsList.css'
import i18next from "i18next";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getAllNewsSuccess, newsFailure, newsStart} from "../../slice/newsSlice";
import NewsService from "../../service/newsService";
import {failure} from "../../slice/generalVariable";
import {Img, Loading} from "../../ui";
import {getDate} from "../../helper/data";

const NewsList = () => {
    const {isLoading, newsList, totalCount} = useSelector(state => state.newsSlice)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [imageLoadedCount, setImageLoadedCount] = useState(0)

    useEffect(() => {
        const getAll = async () => {
            dispatch(newsStart())
            try {
                const response = await NewsService.getAll()

                if (response.success) {
                    dispatch(getAllNewsSuccess({data: response.data, totalCount: response.totalCount}))
                } else {
                    dispatch(failure(response.appErrorDto))
                    dispatch(newsFailure())
                }
            } catch (error) {
                console.log(error)
                dispatch(failure())
            }
        }

        getAll().catch(() => navigate("/"))
    }, []);

    return (
        <>
            {(isLoading || totalCount !== imageLoadedCount) && <Loading/>}
            {(!isLoading && newsList) &&
                <div className='container' style={{display: imageLoadedCount !== totalCount ? "none" : "block"}}>
                    <section className='news-list'>
                        <div className="heading">
                            <h1 className="title">{i18next.t("news")}</h1>
                        </div>
                        <div className="news-list">
                            {newsList.map((news) => {
                                return (
                                    <div className='items' key={news.id}>
                                        <div className='box shadow flexSB'>
                                            <div className='images'>
                                                <Link to={`/news/${news.id}`}>
                                                    <div className='img'>
                                                        <Img
                                                            pictureUrl={news.pictureUrl}
                                                            onLoad={() => setImageLoadedCount(prevState => (prevState + 1))}
                                                        />
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='text'>
                                                <Link to={`/news/${news.id}`}>
                                                    <h1 className='title'>{news.title}</h1>
                                                </Link>
                                                <div className='date'>
                                                    <i className="fa-solid fa-calendar-days"></i>
                                                    <label>{getDate(news.createdAt)}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </section>
                </div>
            }
        </>
    );
};
export default NewsList;