import React, {useEffect, useState} from 'react';
import {closeShareModal} from "../../slice/generalVariable";
import {useDispatch, useSelector} from "react-redux";
import {baseUrlForFrontend} from "../../constant";
import {getLanguage} from "../../helper/persistance-storege";
import i18next from "i18next";

const SharePopup = ({id}) => {
    const dispatch = useDispatch()
    const {isOpenShareModal} = useSelector(state => state.generalVariable)
    const [copyText, setCopyText] = useState('')

    useEffect(() => {
        setCopyText(`${baseUrlForFrontend}product/${id}/${getLanguage()}`)
    }, [id, getLanguage()]);
    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(copyText);
        } catch (err) {
            console.error(
                "Unable to copy to clipboard.",
                err
            );
            alert("Copy to clipboard failed.");
        }
    };

    return (
        <div className={`data-popup d-share ${isOpenShareModal ? 'active' : ''}`}>
            <div className="wrap">
                <div className="data-content">
                    <a href="#0"
                       className="close-trigger"
                       onClick={(e) => {
                           e.preventDefault()
                           dispatch(closeShareModal())
                       }}
                    >
                        <i className="ri-close-line"></i>
                    </a>
                    <div className="form">
                        <label> {i18next.t("copy")} </label>
                        <div className="copy">
                            <p>{copyText}</p>
                            <span onClick={handleCopyClick}><i className="ri-file-copy-line"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SharePopup;