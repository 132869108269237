import i18next from "i18next";
import ValidationError from "../../ui/validationError";
import {Input, Select} from "../../ui";
import React, {useEffect, useState} from "react";
import {FileUpload} from "../index";
import {useDispatch, useSelector} from "react-redux";
import {failureCategory, startCategory, successCategory} from "../../slice/categorySlice";
import CategoryService from "../../service/categoryService";
import {failure} from "../../slice/generalVariable";
import FileService from "../../service/fileService";
import {useNavigate, useParams} from "react-router-dom";
import {logo} from "../../assets";

const CategoryCreate = () => {
    const navigate = useNavigate()
    const {id} = useParams()
    const dispatch = useDispatch()
    const {isLoading} = useSelector(state => state.categorySlice)
    const [categoryUz, setCategoryUz] = useState('')
    const [categoryRu, setCategoryRu] = useState('')
    const [categoryEn, setCategoryEn] = useState('')
    const [hidden, setHidden] = useState('')
    const [picture, setPicture] = useState(null)
    const options = [{
        id: false,
        name: "Ha"
    }, {
        id: true,
        name: "Yo'q"
    }]

    const isEmpty = () => {
        return categoryEn === '' || categoryUz === '' || categoryRu === '' || (picture === null && !id);
    }

    useEffect(() => {
        const getCategoryHandler = async () => {
            if (id !== undefined && id !== null) {
                dispatch(startCategory())
                try {
                    const response = await CategoryService.get(id);

                    if (response.success) {
                        dispatch(successCategory())

                        const category = response.data;
                        console.log(response)
                        setCategoryUz(category.uz)
                        setCategoryRu(category.ru)
                        setCategoryEn(category.en)
                        setHidden(category.hidden ? 'true' : 'false')
                    } else {
                        dispatch(failure(response.appErrorDto))
                        dispatch(failureCategory())
                    }
                } catch (error) {
                    console.log(error)
                    dispatch(failureCategory())
                }
            }
        }

        getCategoryHandler().catch(error => console.log(error));
    }, []);

    const getCategory = () => {
        const category = {
            "uz": categoryUz,
            "ru": categoryRu,
            "en": categoryEn
        }

        if (id) {
            category.id = id;
            category.hidden = hidden === 'true';
        }
        return category;
    }

    const uploadFiles = async (id) => {
        const formData = new FormData();
        formData.append("picture", picture[0]);
        const responseUpload = await FileService.uploadCategoryImage(formData, id)

        if (!responseUpload.success) {
            dispatch(failure(responseUpload.appErrorDto))
            dispatch(failureCategory())
        } else {
            dispatch(successCategory())
            navigate("/admin-page/category-list")
        }
    }

    const updateHandler = async () => {
        dispatch(startCategory())

        try {
            const response = await CategoryService.update(getCategory());
            if (response.success) {
                if (picture) {
                    await uploadFiles(response.data)
                } else {
                    dispatch(successCategory())
                    navigate("/admin-page/category-list")
                }
            } else {
                dispatch(failure(response.appErrorDto))
                dispatch(failureCategory())
            }
        } catch (error) {
            console.log(error)
            dispatch(failureCategory())
        }
    }

    const createHandler = async () => {
        dispatch(startCategory())

        try {
            const response = await CategoryService.add(getCategory());
            if (response.success) {
                await uploadFiles(response.data)
            } else {
                dispatch(failure(response.appErrorDto))
                dispatch(failureCategory())
            }
        } catch (error) {
            console.log(error)
            dispatch(failureCategory())
        }
    }

    const saveHandler = async () => {
        if (id) {
            await updateHandler()
        } else {
            await createHandler()
        }
    }

    return (
        <div className={"text-center"} style={{paddingTop: '15vh'}}>
            <main className={"form-signin m-auto"}>
                <form>
                    <img className="mb-2" src={logo} alt="" width="57" height="57"/>
                    <h1 className="h3 mb-3 fw-normal">Kategoriya Qo'shish</h1>
                    <ValidationError/>
                    <Input label="Kategoriya" state={categoryUz} setState={setCategoryUz}/>
                    <Input label="Категория" state={categoryRu} setState={setCategoryRu}/>
                    <Input label="Products" state={categoryEn} setState={setCategoryEn}/>
                    <p>{i18next.t("Ko'rinadimi")}:</p>
                    {id && <Select setState={setHidden} selected={hidden} options={options}/>}
                    <FileUpload setFiles={(value) => setPicture(value)} multiple={false}/>
                    <button
                        className="mt-2 w-100 btn btn-lg btn-primary"
                        type="submit"
                        onClick={saveHandler}
                        disabled={isLoading || isEmpty()}
                    >
                        {isLoading ? `${i18next.t("loading")}...` : `${i18next.t("save")}`}
                    </button>
                </form>
            </main>
        </div>
    );
};

export default CategoryCreate;