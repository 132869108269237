import React from 'react';
import {Link} from "react-router-dom";

const Socials = () => {
    return (
        <>
            <Link to={'#'} target={'_blank'}>
                <span className="fa-brands fa-youtube" style={{color: "#ff0000"}}></span>
            </Link>
            <Link to={'https://www.instagram.com/itm_mchj/'} target={'_blank'}>
                <span className="fa-brands fa-instagram" style={{color: '#ac2bac'}}></span>
            </Link>
            <Link to={'https://t.me/ITMRasmiy'} target={'_blank'}>
                <span className="fa-brands fa-telegram" style={{color: '#0000ff'}}></span>
            </Link>
            <Link to={'https://www.facebook.com/InnovationTechnologiesCenter/'}
                  target={'_blank'}>
                <span className="fa-brands fa-facebook" style={{color: '#3b5998'}}></span>
            </Link>
        </>
    );
};

export default Socials;