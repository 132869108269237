import React, {useEffect, useState} from 'react';
import QuestionService from "../../service/questionService";
import {useNavigate} from "react-router-dom";
import {DisplayList} from "../index";
import i18next from "i18next";

const QuestionList = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [questions, setQuestions] = useState([])
    const navigate = useNavigate()


    useEffect(() => {
        const getAllQuestions = async () => {
            setIsLoading(true)
            try {
                const response = await QuestionService.getAllQuestions();

                if (response.success) {
                    setQuestions(response.data)
                } else {
                    navigate('/admin-page')
                }
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false)
            }
        }

        getAllQuestions()
            .catch(error => console.log(error))
    }, []);

    const headers = [
        {
            Header: i18next.t("name"),
            accessor: "name",
        },
        {
            Header: i18next.t("email_or_phone"),
            accessor: "email",
        },
        {
            Header: i18next.t("question_content"),
            accessor: "questionContent",
        },
        {
            Header: i18next.t("vendorCode"),
            accessor: "vendorCode",
        },]

    const deleteHandler = async (id) => {
        await QuestionService.delete(id)
        window.location.reload();
    }

    return (
        <DisplayList
            data={questions}
            headers={headers}
            deleteHandler={deleteHandler}
            isLoading={isLoading}
            addButtonHidden={true}
            createUrl={"create"}
        />
    );
};

export default QuestionList;