import axios from "./api";

const ProductService = {

    async create(product) {
        return (await axios.post("product/create", product)).data;
    },

    async get(id) {
        return (await axios.get(`product/get/${id}`)).data;
    },

    async delete(id) {
        await axios.delete(`product/delete/${id}`)
    },

    async getForUpdate(id) {
        return (await axios.get(`product/getForUpdate/${id}`)).data;
    },

    async update(product) {
        return (await axios.put(`product/update`, product)).data;
    },

}

export default ProductService;