import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {closeAnswerModal} from "../../slice/generalVariable";
import {Input, TextArea} from "../../ui";
import QuestionService from "../../service/questionService";
import i18next from "i18next";

const QuestionPopup = () => {
    const dispatch = useDispatch()
    const {isOpenAnswerModal} = useSelector(state => state.generalVariable)
    const [name, setName] = useState("")
    const [emailOrPhoneNumber, setEmailOrPhoneNumber] = useState("")
    const [content, setContent] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const {product} = useSelector(state => state.productSlice)

    const writeQuestion = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            if (name === '' || emailOrPhoneNumber === '' || content === '') {
                return;
            }
            const question = {
                "name": name,
                "email": emailOrPhoneNumber,
                "questionContent": content,
                "productId": product.id
            }
            const response = await QuestionService.writeQuestion(question);

            if (response.success) {
                window.location.reload()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={`data-popup d-question ${isOpenAnswerModal ? "active" : ""}`}>
            <div className="wrap">
                <div className="data-content">
                    <a href="#0" className="close-trigger" onClick={(e) => {
                        e.preventDefault()
                        dispatch(closeAnswerModal())
                    }}>
                        <i className="ri-close-line"></i>
                    </a>
                    <h3>{i18next.t("question")}</h3>
                    <form action="">
                        <div><Input label={i18next.t("name")} setState={setName}/></div>
                        <div><Input label={i18next.t("email_or_phone")} setState={setEmailOrPhoneNumber}/></div>
                        <div><TextArea label={i18next.t("your_question") + " ..."} setState={setContent}/></div>
                        <div className="button">
                            <button type="submit"
                                    className="secondary-btn"
                                    disabled={isLoading}
                                    onClick={writeQuestion}
                            >
                                {i18next.t("submit")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default QuestionPopup;