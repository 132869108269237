import React, {useEffect, useState} from 'react';
import {Input, Select, TextArea} from "../../ui";
import {useDispatch, useSelector} from "react-redux";
import ProductService from "../../service/productService";
import FileService from "../../service/fileService";
import ValidationError from "../../ui/validationError";
import {failure} from "../../slice/generalVariable";
import i18next from "i18next";
import {FileUpload} from "../index";
import {failureCategory, getCategoriesSuccess, startCategory} from "../../slice/categorySlice";
import CategoryService from "../../service/categoryService";
import {logo} from "../../assets";
import {useNavigate, useParams} from "react-router-dom";
import {getProductFailure, getProductSuccess} from "../../slice/productSlice";

const ProductCreate = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {categories} = useSelector(state => state.categorySlice)
    const {id} = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [nameUz, setNameUz] = useState("")
    const [nameRu, setNameRu] = useState("")
    const [nameEn, setNameEn] = useState("")
    const [descriptionUz, setDescriptionUz] = useState("")
    const [descriptionRu, setDescriptionRu] = useState("")
    const [descriptionEn, setDescriptionEn] = useState("")
    const [price, setPrice] = useState(0)
    const [categoryId, setCategoryId] = useState(null)
    const [hidden, setHidden] = useState(true)
    const [vendorCode, setVendorCode] = useState('')
    const [files, setFiles] = useState(null)
    const [components, setComponents] = useState([
        {id: 0, inputValue: ''},
        {id: 1, inputValue: ''},
        {id: 2, inputValue: ''}
    ]);
    const options = [{
        id: false,
        name: "Ha"
    }, {
        id: true,
        name: "Yo'q"
    }]

    const getCategoriesHandler = async () => {
        dispatch(startCategory())
        try {
            const response = await CategoryService.getAll();

            if (response.success) {
                dispatch(getCategoriesSuccess(response.data))
            } else {
                dispatch(failure(response.appErrorDto))
                dispatch(failureCategory())
            }
        } catch (error) {
            dispatch(failureCategory())
            console.log(error)
        }
    }

    const getTechnicalData = (technicalDatas) => {
        const components = []
        technicalDatas.forEach((technicalData, index) => {
            components.push({id: 3 * index, inputValue: technicalData.uz, productId: technicalData.id});
            components.push({id: 3 * index + 1, inputValue: technicalData.uz});
            components.push({id: 3 * index + 2, inputValue: technicalData.uz});
        })

        return components;
    }

    const getProductHandler = async (id) => {
        setIsLoading(true)
        try {
            const response = await ProductService.getForUpdate(id)

            if (response.success) {
                const product = response.data
                product.pictureUrls = []
                dispatch(getProductSuccess(product))
                const name = product.name
                const description = product.description

                setNameUz(name.uz)
                setNameRu(name.ru)
                setNameEn(name.en)
                setDescriptionUz(description.uz)
                setDescriptionRu(description.ru)
                setDescriptionEn(description.en)
                setCategoryId(product.categoryId)
                setPrice(product.price)
                setVendorCode(product.vendorCode)
                setHidden(product.hidden)
                console.log(product.hidden)
                setComponents(getTechnicalData(product.technicalDatas))
            } else {
                dispatch(getProductFailure())
                dispatch(failure(response.appErrorDto))
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getCategoriesHandler()
            .catch((error) => console.log(error))
        if (id) {
            getProductHandler(id)
                .catch((error) => console.log(error))
        }
    }, []);

    useEffect(() => {
        if (categories.length !== 0 && !id) {
            setCategoryId(categories[0].id)
            console.log(categories)
        }
    }, [categories]);


    const handleAddComponent = () => {
        setComponents([...components,
            {id: components.length, inputValue: ''},
            {id: components.length + 1, inputValue: ''},
            {id: components.length + 2, inputValue: ''}]
        );
    };

    const handleRemoveComponent = () => {
        let componentsLength = components.length

        if (componentsLength === 3) {
            return
        }
        const deleteComponentsId = [componentsLength - 1, componentsLength - 2, componentsLength - 3]
        setComponents(components.filter(component => !deleteComponentsId.includes(component.id)))
    }

    const handleInputChange = (id, value) => {
        setComponents((prevComponents) =>
            prevComponents.map((component) =>
                component.id === id ? {...component, inputValue: value} : component
            )
        );
    };

    const getProduct = () => {
        const technicalDatas = []
        let technicalData = {}

        for (let i = 0; i < components.length; i++) {
            if (i % 3 === 0) {
                technicalData = {}
                technicalData.id = components[i]?.productId
                technicalData.uz = components[i].inputValue
            } else if (i % 3 === 1) {
                technicalData.ru = components[i].inputValue
            } else {
                technicalData.en = components[i].inputValue
                technicalDatas.push(technicalData)
            }
        }

        return {
            name: {"uz": nameUz, "ru": nameRu, "en": nameEn},
            description: {"uz": descriptionUz, "ru": descriptionRu, "en": descriptionEn},
            "price": price,
            "technicalDatas": technicalDatas,
            "categoryId": categoryId,
            "vendorCode": vendorCode
        };
    }

    const navigateProduct = (id) => {
        navigate(`/product/${id}`)
        window.location.reload()
    }

    const fileUploadHandler = async (id) => {
        try {
            const formData = new FormData()
            const arrayFiles = Array.from(files)
            arrayFiles.forEach((file) => {
                formData.append(`files`, file);
            });
            const uploadResponse = await FileService.upload(formData, id);
            if (uploadResponse.success) {
                navigateProduct(id)
            } else {
                dispatch(failure(uploadResponse.appErrorDto))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const updateHandler = async (id) => {
        setIsLoading(true)
        try {
            const product = getProduct()
            product.id = id
            const response = await ProductService.update(product)

            if (response.success) {
                if (files) {
                    await fileUploadHandler(id)
                } else {
                    navigateProduct(id)
                }
            } else {
                dispatch(failure(response.appErrorDto))
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const createHandler = async () => {
        setIsLoading(true)
        try {
            const product = getProduct()
            const response = await ProductService.create(product)

            if (response.success) {
                await fileUploadHandler(response.data)
            } else {
                dispatch(failure(response.appErrorDto))
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const saveHandler = async () => {
        if (id) {
            await updateHandler(id)
        } else {
            await createHandler()
        }
    }

    const isEmptyStates = () => {
        for (let component of components) {
            if (component.inputValue.length === 0) {
                return true;
            }
        }

        return nameUz === '' || nameRu === '' || nameEn === '' ||
            descriptionUz === '' || descriptionRu === '' || descriptionEn === '' ||
            categoryId === null || vendorCode === '' || (files === null && !id);
    }

    return (
        <div className={"product-create"}>
            <div className="container">
                <div className={"d-flex justify-content-center align-items-center"}>
                    <img src={logo} alt="" width="57" height="57"/>
                    <h1 className="h3 mt-2 fw-normal">{i18next.t("add_product")}</h1>
                </div>
                <ValidationError/>
                <p><strong>{i18next.t("name")}:</strong></p>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                        <Input label={'Nomi'} state={nameUz} setState={setNameUz}/>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <Input label={'Имя'} state={nameRu} setState={setNameRu}/>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <Input label={'Name'} state={nameEn} setState={setNameEn}/>
                    </div>
                </div>
                <p><strong>{i18next.t("description")}:</strong></p>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                        <TextArea
                            label={'Tarif'}
                            state={descriptionUz}
                            setState={setDescriptionUz}
                            height={"150px"}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <TextArea
                            label={'Описание'}
                            state={descriptionRu}
                            setState={setDescriptionRu}
                            height={"150px"}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <TextArea
                            label={'Description'}
                            state={descriptionEn}
                            setState={setDescriptionEn}
                            height={"150px"}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="row">
                        <div className={"col-lg-4 col-md-6 col-12"}>
                            <p><strong>{i18next.t("upload")}:</strong></p>
                        </div>
                        <div className={"col-lg-4 col-md-6 col-12"}>
                            <p><strong>{i18next.t("price")}:</strong></p>
                        </div>
                        {id && <div className={"col-lg-4 col-md-6 col-12"}>
                            <p>{i18next.t("Ko'rinadimi")}:</p>
                        </div>}
                    </div>
                    <div className="row">
                        <div className={"col-lg-4 col-md-6 col-12"} style={{marginBottom: "15px"}}>
                            <FileUpload setFiles={setFiles}/>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <Input
                                label={i18next.t("price")}
                                state={price}
                                setState={setPrice}
                                type={"number"}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            {id && <Select setState={setHidden} selected={hidden} options={options}/>}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="row">
                        <div className={"col-lg-4 col-md-6 col-12"}>
                            <p><strong>{i18next.t("vendorCode")}:</strong></p>
                        </div>
                        <div className={"col-lg-4 col-md-6 col-12"}>
                            <p><strong>{i18next.t("category")}:</strong></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col-lg-4 col-md-6 col-12"}>
                            <Input
                                label={i18next.t("vendorCode")}
                                state={vendorCode}
                                setState={setVendorCode}
                            />
                        </div>
                        <div className={"col-lg-4 col-md-6 col-12"}>
                            <Select options={categories}
                                    setState={setCategoryId}
                                    label={i18next.t("category")}
                                    classes={"category-select"}/>
                        </div>
                    </div>
                </div>
                <p><strong>{i18next.t("technical_data")}:</strong></p>
                <div className="row">
                    {components.map((component) => (
                        <div key={component.id} className={"col-lg-4 col-md-6 col-12"}>
                            <Input
                                label={component.id % 3 === 0 ? "Texnik ma'lumot" : component.id % 3 === 1 ? "Технические данные" : "Technical data"}
                                onInputChange={(value) => handleInputChange(component.id, value)}
                                state={component.inputValue}
                            />
                        </div>
                    ))}
                </div>
                <div className="d-flex justify-content-center gap-2">
                    <button onClick={handleAddComponent} style={{height: '58px', width: "58px"}}>
                        +
                    </button>
                    <button onClick={handleRemoveComponent} style={{height: '58px', width: "58px"}}>
                        -
                    </button>
                </div>
                <button
                    className="mt-4 w-100 btn btn-lg btn-primary"
                    type="submit"
                    onClick={saveHandler}
                    disabled={isLoading || isEmptyStates()}
                >
                    {isLoading ? `${i18next.t("loading")}...` : `${i18next.t("save")}`}
                </button>
            </div>
        </div>
    );
};

export default ProductCreate;