import axios from "./api";

const FileService = {

    async upload(formData, productId) {
        return (await axios.post(`file/upload/product/${productId}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
        })).data
    },

    async uploadCategoryImage(formData, categoryId) {
        return (await axios.post(`file/upload/category/${categoryId}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
        })).data
    },

    async uploadNewsImages(formData, newsID) {
        return (await axios.post(`file/upload/news/${newsID}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
        })).data
    },

}

export default FileService;