import {createSlice} from "@reduxjs/toolkit";
import {setFavouriteIdsToLocalStorage} from "../helper/persistance-storege";

const initialState = {
    isLoading: false,
    categories: [],
    category: {},
    searchValue: "",
    sorting: "",
    categoryId: "",
    products: [],
    productTotalCount: -1,
    categoryTotalCount: -1,
    favouriteIds: [],
}

export const categorySlice = createSlice({
    name: "category",
    initialState,
    reducers: {
        startCategory: (state) => {
            state.isLoading = true;
        },
        successCategory: (state) => {
            state.isLoading = false;
        },
        failureCategory: (state) => {
            state.isLoading = false
        },
        getCategoriesSuccess: (state, action) => {
            state.isLoading = false;
            state.categories = [...action.payload]
            state.categoryTotalCount = state.categories.length
        },
        setSearch: (state, action) => {
            state.searchValue = action.payload;
        },
        setCategoryId: (state, action) => {
            state.categoryId = action.payload
        },
        getAllProductSuccess: (state, action) => {
            state.isLoading = false
            state.products = action.payload
            state.productTotalCount = action.payload.length
        },
        setSorting: (state, action) => {
            state.sorting = action.payload
        },
        setFavouriteIds: (state, action) => {
            state.favouriteIds = action.payload
        },
        categoryClear: (state) => {
            state.categories = []
            state.products = []
            state.searchValue = ''
            state.sorting = ''
            state.categoryId = ''
            state.productTotalCount = -1
            state.categoryTotalCount = -1
        },
        toggleFavourite: (state, action) => {
            const id = action.payload
            const productId = parseInt(id)
            let result;
            if (state.favouriteIds && state.favouriteIds.includes(productId)) {
                result = state.favouriteIds.filter(favouriteId => favouriteId !== productId)
            } else {
                result = state.favouriteIds ? [...state.favouriteIds, productId] : [productId]
            }
            state.favouriteIds = result
            setFavouriteIdsToLocalStorage(result)
        },
    }
})

export const {
    startCategory,
    successCategory,
    failureCategory,
    getCategoriesSuccess,
    setSearch,
    setCategoryId,
    getAllProductSuccess,
    setSorting,
    setFavouriteIds,
    categoryClear,
    toggleFavourite,
} = categorySlice.actions
export default categorySlice.reducer