import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/app/app';
import reportWebVitals from './reportWebVitals';
import './translations/i18n';
import i18next from "i18next";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux"
import {store} from "./store";

i18next.changeLanguage(localStorage.getItem('lang'))

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <App/>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals();
