import axios from "./api";

const ReviewService = {

    async writeReview(review) {
        return (await axios.post("review/create", review)).data;
    }

}

export default ReviewService;