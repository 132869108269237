import React, {useEffect, useState} from 'react';
import '../../index.css'
import {useDispatch, useSelector} from "react-redux";
import {failure, openSidebar} from "../../slice/generalVariable";
import {CategoryProduct, Search, Sidebar} from "../index";
import {failureCategory, getAllProductSuccess, setSearch, setSorting, startCategory} from "../../slice/categorySlice";
import CategoryService from "../../service/categoryService";
import {Loading} from "../../ui";
import i18next from "i18next";
import {getLanguage} from "../../helper/persistance-storege";
import {fileDownload, sortListEn, sortListRu, sortListUz} from "../../constant";

const Products = () => {
    const dispatch = useDispatch()
    const [sorted, setSorted] = useState(false)
    const [sortValue, setSortValue] = useState("")
    const {isOpenSideBar} = useSelector(state => state.generalVariable)
    const {
        searchValue,
        sorting,
        categoryId,
        products,
        isLoading,
        favouriteIds,
        productTotalCount,
    } = useSelector(state => state.categorySlice)
    const lang = getLanguage()
    const [sortList, setSortList] = useState(sortListUz)
    const [productImagesLoad, setProductImagesLoad] = useState(false)
    const [categoryImagesLoad, setCategoryImagesLoad] = useState(false)

    useEffect(() => {
        if (lang === 'en') {
            setSortList(sortListEn)
        } else if (lang === 'ru') {
            setSortList(sortListRu)
        } else {
            setSortList(sortListUz)
        }
    }, [lang])

    useEffect(() => {
        setSortValue(sortList[0])
    }, [sortList])

    useEffect(() => {
        getAllProduct().catch((error) => console.log(error))
    }, [sorting, categoryId])

    useEffect(() => {
        if (!isLoading && productTotalCount === 0) {
            setProductImagesLoad(true)
        }
        if (products && products.length !== 0) {
            setProductImagesLoad(false)
            const loadImage = src =>
                new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = () => resolve(img)
                    img.onerror = reject;
                    img.src = src;
                })

            Promise
                .all(products.map(product => loadImage(`${fileDownload}${product.pictureUrl}`)))
                .then((images) => {
                    setProductImagesLoad(true)
                })
                .catch(err => console.log("Failed to load images", err))
        }
    }, [products])

    const getAllProduct = async () => {
        setProductImagesLoad(false)
        dispatch(startCategory())
        try {
            const filter = {
                "searchValue": searchValue,
                "sorting": sorting,
                "categoryId": categoryId
            }
            const response = await CategoryService.getAllProduct(filter)

            if (response.success) {
                dispatch(getAllProductSuccess(response.data))
            } else {
                dispatch(failure(response.appErrorDto))
                dispatch(failureCategory())
            }
        } catch (error) {
            console.log(error)
            dispatch(failureCategory())
        }
    }

    const sorter = (e, index) => {
        e.preventDefault()
        setSorted(false)

        e.currentTarget.parentNode.childNodes
            .forEach(li => li.classList.remove('active'));
        e.currentTarget.classList.add('active')
        setSortValue(e.currentTarget.innerText)
        dispatch(setSorting(sortListEn[index]))
    }

    return (
        <>
            {(isLoading || !productImagesLoad || !categoryImagesLoad) && <Loading/>}
            {products &&
                <div className={"container"}
                     style={{display: isLoading || !productImagesLoad || !categoryImagesLoad ? "none" : "block"}}
                >
                    <div className={'page-category'}>
                        <main>
                            <div className={'section'}>
                                <div className="container wide">
                                    <div className="wrap">
                                        <div className="content">
                                            <Search
                                                state={searchValue}
                                                setState={(value) => dispatch(setSearch(value))}
                                                label={i18next.t("search_title_definition")}
                                                getAllProduct={getAllProduct}
                                            />
                                            <Sidebar setCategoryImagesLoad={setCategoryImagesLoad}/>
                                            <div className="category-content">
                                                <div className="sorter">
                                                    <a
                                                        href="#0"
                                                        className="menu-trigger"
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            dispatch(openSidebar())
                                                        }}
                                                        style={{display: `${isOpenSideBar ? 'none' : "flex"}`}}
                                                    >
                                                        <i className="ri-filter-line"></i>
                                                    </a>
                                                    <div className="right">
                                                        <div className="sort-list">
                                                            <div className="wrap">
                                                                <div
                                                                    className="opt-trigger"
                                                                    onMouseEnter={() => setSorted(true)}
                                                                    onMouseLeave={() => setSorted(false)}
                                                                >
                                                                    <span className="value">{sortValue}</span>
                                                                    <i className="ri-arrow-down-s-line"></i>
                                                                </div>
                                                                <ul className={sorted ? 'show' : ''}
                                                                    onMouseEnter={() => setSorted(true)}
                                                                    onMouseLeave={() => setSorted(false)}
                                                                >
                                                                    {sortList.map(
                                                                        (item, index) => (
                                                                            <li
                                                                                key={index}
                                                                                className={index === 0 ? 'active' : ''}
                                                                                onClick={(e) => sorter(e, index)}>
                                                                                <a href="#0"
                                                                                   onClick={event => event.preventDefault()}>{item}</a>
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="dotgrid">
                                                    <div className="wrapper">
                                                        {products.map(product => (
                                                            <CategoryProduct key={product.id}
                                                                             pictureUrl={product.pictureUrl}
                                                                             name={product.name}
                                                                             price={product.price}
                                                                             id={product.id}
                                                                             isFavourite={favouriteIds && favouriteIds.includes(product.id)}
                                                            />))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>}
        </>
    );
};

export default Products;