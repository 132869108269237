import React, {useEffect, useState} from "react";
import {Img, Loading} from "../../ui";
import {enMonths, ruMonths, uzMonths} from "../../constant";
import {useDispatch, useSelector} from "react-redux";
import {getAllNewsSuccess, newsFailure, newsStart} from "../../slice/newsSlice";
import NewsService from "../../service/newsService";
import {failure} from "../../slice/generalVariable";
import i18next from "i18next";
import {getLanguage} from "../../helper/persistance-storege";
import {Link} from "react-router-dom";
import useWindowDimensions from "../useWindowDimensions";

const Main = () => {
    const dispatch = useDispatch()
    const {isLoading, newsList, totalCount} = useSelector(state => state.newsSlice)
    const {navbarHeight} = useSelector(state => state.generalVariable)
    const {height} = useWindowDimensions();
    const lang = getLanguage();
    const [months, setMonths] = useState(uzMonths)
    const [imageLoadedCount, setImageLoadedCount] = useState(0)

    useEffect(() => {
        if (lang === 'en') {
            setMonths(enMonths)
        } else if (lang === 'ru') {
            setMonths(ruMonths)
        } else {
            setMonths(uzMonths)
        }
    }, [lang]);

    useEffect(() => {
        const getAll = async () => {
            dispatch(newsStart())

            try {
                const response = await NewsService.getForLandingPage()

                if (response.success) {
                    dispatch(getAllNewsSuccess({data: response.data, totalCount: response.totalCount}))
                } else {
                    dispatch(newsFailure())
                    dispatch(failure(response.appErrorDto))
                }
            } catch (error) {
                console.log(error)
                dispatch(newsFailure())
            }
        }

        getAll().catch(error => console.log(error))
    }, []);

    useEffect(() => {
        if (imageLoadedCount === totalCount) {
            let nextDom = document.getElementById('next');
            let prevDom = document.getElementById('prev');

            let carouselDom = document.querySelector('.carousel');
            let SliderDom = carouselDom.querySelector('.carousel .list');

            if (!(nextDom && prevDom && carouselDom && SliderDom)) {
                return
            }

            let timeRunning = 1000;
            let timeAutoNext = 25000;

            nextDom.onclick = function () {
                showSlider('next');
            }

            prevDom.onclick = function () {
                showSlider('prev');
            }
            let runTimeOut;
            let runNextAuto = setTimeout(() => {
                nextDom.click();
            }, timeAutoNext)

            function showSlider(type) {
                let SliderItemsDom = SliderDom.querySelectorAll('.carousel .list .item');

                if (!SliderItemsDom) {
                    return
                }

                if (type === 'next') {
                    SliderDom.appendChild(SliderItemsDom[0]);
                    carouselDom.classList.add('next');
                } else {
                    SliderDom.prepend(SliderItemsDom[SliderItemsDom.length - 1]);
                    carouselDom.classList.add('prev');
                }
                clearTimeout(runTimeOut);
                runTimeOut = setTimeout(() => {
                    carouselDom.classList.remove('next');
                    carouselDom.classList.remove('prev');
                }, timeRunning);

                clearTimeout(runNextAuto);
                runNextAuto = setTimeout(() => {
                    nextDom.click();
                }, timeAutoNext)
            }
        }
    }, [imageLoadedCount]);

    return (
        <>
            {(isLoading || totalCount !== imageLoadedCount) && <Loading/>}
            {(!isLoading) &&
                (<div className="carousel"
                      style={{
                          height: (height - navbarHeight) + 'px',
                          display: imageLoadedCount === totalCount ? "block" : "none"
                      }}>
                    <div className="content">
                        <div className="list">
                            <>
                                {newsList?.map(news => (<div className="item" key={news.id}>
                                    <Img
                                        pictureUrl={news.pictureUrl}
                                        onLoad={() => setImageLoadedCount(prevState => (prevState + 1))}
                                    />
                                    <div className="content">
                                        <div className="title">{news.title}</div>
                                        <Link to={`/news/${news.id}`}>
                                            <div className="buttons">
                                                <button>{i18next.t("more")}</button>
                                            </div>
                                        </Link>
                                        <div className="time">
                                            <p>{news.createdAt[2]}</p>
                                            <p>{months[news.createdAt[1] - 1]}</p>
                                        </div>
                                    </div>
                                </div>))}
                            </>
                        </div>
                    </div>
                    <div className="arrows">
                        <button id="prev">{'<'}</button>
                        <button id="next">></button>
                    </div>
                </div>)
            }
        </>
    );
};

export default Main;