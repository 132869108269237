import React from 'react';
import i18next from "i18next";

const FileUpload = ({setFiles, key, multiple = true}) => {
    const handleFileUpload = (e) => {
        e.preventDefault()
        setFiles(e.target.files)
    }

    return (
        <div key={key} className={"file-upload form-control"}>
            <label htmlFor="imageUpload">{i18next.t('upload')}</label>
            <input
                id={"imageUpload"}
                className="form-control"
                type="file"
                multiple={multiple}
                onChange={handleFileUpload}
                style={{display: "none"}}
                accept={".jpg,.jpeg,.png"}
                placeholder={"men"}
            />
        </div>
    );
};

export default FileUpload;