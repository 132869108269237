import axios from './api'

const NewsService = {

    async get(id) {
        return (await axios.get(`news/get/${id}`)).data;
    },

    async create(news) {
        return (await axios.post(`news/create`, news)).data;
    },

    async getAll() {
        return (await axios.get("news/getAll")).data
    },

    async getForUpdate(id) {
        return (await axios.get(`news/getForUpdate/${id}`)).data;
    },

    async update(news) {
        return (await axios.put("news/update", news)).data;
    },

    async getForLandingPage() {
        return (await axios.get("news/landing-page")).data;
    },

    async delete(id){
       await axios.delete(`news/delete/${id}`)
    },

}

export default NewsService;