import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    product: null,
    isLoading: false,
    imageTotalCount: -1
}

export const productSlice = createSlice({
    name: "product",
    initialState: initialState,
    reducers: {
        getProductStart: (state) => {
            state.isLoading = true;
        },
        getProductSuccess: (state, action) => {
            state.isLoading = false;
            state.product = action.payload
            state.imageTotalCount = action.payload.pictureUrls.length
        },
        getProductFailure: (state) => {
            state.isLoading = false
        },
        productClear: (state) => {
            Object.assign(state, initialState)
        },
    }
})

export const {
    getProductStart,
    getProductSuccess,
    getProductFailure,
    productClear,
} = productSlice.actions
export default productSlice.reducer