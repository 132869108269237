import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import {productClear} from "../slice/productSlice";
import {categoryClear} from "../slice/categorySlice";

function CleanState({children}) {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
            dispatch(productClear())
            dispatch(categoryClear())
    }, [location.pathname])

    return <>{children}</>;
}

export default CleanState;