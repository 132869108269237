import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getUserFailure, getUsersStart, getUsersSuccess} from "../../slice/authSlice";
import AuthService from "../../service/authService";
import {failure} from "../../slice/generalVariable";
import {DisplayList} from "../index";

const UserList = () => {
    const dispatch = useDispatch()
    const {isLoading, users} = useSelector(state => state.authSlice)

    useEffect(() => {
        const getAll = async () => {
            dispatch(getUsersStart())

            try {
                const response = await AuthService.getAll()

                if (response.success) {
                    dispatch(getUsersSuccess(response.data))
                } else {
                    dispatch(getUserFailure())
                    dispatch(failure(response.data.appErrorDto))
                }
            } catch (error) {
                console.log(error)
                dispatch(getUserFailure())
            }
        }

        getAll().catch(error => console.log(error))
    }, []);

    const deleteHandler = async (username) => {
        await AuthService.delete(username);
        window.location.reload();
    };

    const headers = [
        {
            Header: "Username",
            accessor: "username",
        },]


    return (
        <DisplayList
            data={users}
            headers={headers}
            deleteHandler={deleteHandler}
            isLoading={isLoading}
            createUrl={"/admin-page/register"}
        />
    );
};

export default UserList;