import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {closeReviewModal} from "../../slice/generalVariable";
import {Input, TextArea} from "../../ui";
import ReactDOM from "react-dom/client";
import ReviewService from "../../service/reviewService";
import i18next from "i18next";

const ReviewPopup = () => {
    const dispatch = useDispatch()
    const {isOpenReviewModal} = useSelector(state => state.generalVariable)
    const {product} = useSelector(state => state.productSlice)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [rating, setRating] = useState(0)
    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const getStarts = () => {
        const elements = [];

        for (let index = 5; index > 0; index--) {
            elements.push(
                <React.Fragment key={index}>
                    <input type="radio" name="rating" id={`star${index}`}/>
                    <label htmlFor={`star${index}`} onClick={() => setRating(index)}>
                        <i className="ri-star-fill"></i>
                    </label>
                </React.Fragment>
            );
        }

        return elements;
    }

    const clearState = () => {
        setName("")
        setEmail("")
        setRating(0)
        setTitle("")
        setContent("")
    }

    const writeReview = async (e) => {
        e.preventDefault()

        const review = {
            "name": name,
            "email": email,
            "rating": rating,
            "title": title,
            "reviewContent": content,
            "productId": product.id
        }
        setIsLoading(true);

        try {
            const response = await ReviewService.writeReview(review);

            if (response.success) {
                window.location.reload();
                clearState()
                dispatch(closeReviewModal())
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={`data-popup d-review ${isOpenReviewModal ? 'active' : ''}`}>
            <div className="wrap">
                <div className="data-content">
                    <a href="#0" className="close-trigger" onClick={(e) => {
                        e.preventDefault()
                        dispatch(closeReviewModal())
                    }}>
                        <i className="ri-close-line"></i>
                    </a>
                    <h3>{i18next.t("write_review")}</h3>
                    <form action="">
                        <div className="dotgrid">
                            <div className="wrapper">
                                <div><Input label={i18next.t("name")} setState={setName}/></div>
                                <div><Input type="email" label={i18next.t("email")} setState={setEmail}/></div>
                            </div>
                        </div>
                        <div className="rating">
                            <span>{i18next.t("your_rating")}:</span>
                            <div className="starts">
                                {getStarts()}
                            </div>
                        </div>
                        <div><Input label={i18next.t("review_title")} setState={setTitle}/></div>
                        <div><TextArea label={`${i18next.t("your_review")}...`} setState={setContent} height={"150px"}/>
                        </div>
                        <div className="button">
                            <button type="submit" className="btn secondary-btn" disabled={isLoading || name === "" || email === '' || title === '' || content === ''} onClick={writeReview}>
                                {isLoading ? i18next.t("loading") : i18next.t("submit")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ReviewPopup;