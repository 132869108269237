import React, {useEffect} from 'react';
import {getLanguage} from "../../helper/persistance-storege";
import {useNavigate, useParams} from "react-router-dom";

const ProductWithLanguage = () => {
    const {productId, lang} = useParams()
    const navigate = useNavigate()

    const changeLanguage = () => {
        console.log(`${productId}  va ${lang}`)
        if (getLanguage() !== lang && ["uz", "ru", "en"].includes(lang)) {
            localStorage.setItem('lang', lang);
            window.location.reload();
        }
    };

    useEffect(() => {
        changeLanguage()
        navigate(`/product/${productId}`)
    }, []);


    return <></>;
};

export default ProductWithLanguage;