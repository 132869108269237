import React, {useEffect, useState} from "react"
import "./news.css"
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation} from "swiper/modules";
import {fileDownload} from "../../constant";
import {getDate} from "../../helper/data";
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css'
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {newsFailure, newsGetSuccess, newsStart} from "../../slice/newsSlice";
import NewsService from "../../service/newsService";
import {failure} from "../../slice/generalVariable";
import {Loading} from "../../ui";
import ReactQuill from "react-quill";
import {Actions} from "../index";
import useWindowDimensions from "../useWindowDimensions";

const News = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {loggedIn} = useSelector(state => state.authSlice)
    const {isLoading, news} = useSelector(state => state.newsSlice)
    const [imgHeight, setImgHeight] = useState(0);
    const {height, width} = useWindowDimensions();

    const getNews = async (id) => {
        dispatch(newsStart())
        try {
            const response = await NewsService.get(id)

            if (response.success) {
                dispatch(newsGetSuccess(response.data))
            } else {
                dispatch(failure(response.appErrorDto))
                dispatch(newsFailure())
            }
        } catch (error) {
            console.log(error)
            dispatch(newsFailure())
        }
    }

    useEffect(() => {
        getNews(id).catch(() => navigate('/news'))
    }, []);

    const adjustImageHeight = () => {
        const img = document.querySelector(`.details img`);
        if (img) {
            const width = img.clientWidth;
            const newHeight = width * 0.7;
            setImgHeight(newHeight)
        }
    };

    useEffect(() => {
        adjustImageHeight()
    }, [width]);

    const paginationSetting = {
        centeredSlides: true,
        loop: true,
        autoplay: {
            delay: 6000,
            disableOnInteraction: false,
        },
        navigation: true,
        modules: [Autoplay, Navigation],
        className: "mySwiper"
    }

    const updateHandler = () => {
        navigate(`/admin-page/news-create/${news.id}`)
    }

    const deleteHandler = async () => {
        await NewsService.delete(news.id)
        navigate("/news-list")
    }

    return (
        <>
            {(isLoading || !news) && <Loading/>}
            {(!isLoading && news) &&
                <main>
                    <div className='container'>
                        <section className='mainContent details'>
                            <h1 className='title'>{news.title}</h1>
                            <hr/>
                            <div className='date'>
                                <i className="fa-solid fa-calendar-days"></i>
                                <label>{news.createdAt && getDate(news.createdAt)}</label>
                            </div>
                            <Swiper {...paginationSetting}>
                                <>
                                    {news.pictureUrls.map((pictureUrl, index) =>
                                        (<SwiperSlide key={index}>
                                            <div className="item" onLoad={() => {
                                                adjustImageHeight();
                                            }}>
                                                <img src={`${fileDownload}${pictureUrl}`}
                                                     alt='not found picture'
                                                     width={"100%"}
                                                     style={imgHeight !== 0 ? {height: imgHeight} : undefined}
                                                />
                                            </div>
                                        </SwiperSlide>))
                                    }
                                </>
                            </Swiper>
                            <div className='body'>
                                <div className="preview">
                                    <ReactQuill value={news.body} readOnly={true} theme={"bubble"}/>
                                </div>
                            </div>
                            {loggedIn && <Actions updateHandler={updateHandler} deleteHandler={deleteHandler}/>}
                        </section>
                    </div>
                </main>
            }
        </>
    )
}

export default News